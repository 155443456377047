@font-face {
  font-family: "Road_Rage";
  src: url("./../Fonts/Road_Rage.otf");
}
@font-face {
  font-family: "Forque";
  src: url("./../Fonts/Forque.ttf");
}


.App {
    text-align: center;
    display: flex;
    height: 100%;
}

.body-layout {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #101010, #151515, #202020, #303030, #434343);
    border-radius: 1%;

    /* width: clamp(300px,100%,100%); */
    margin-top: 20px;
    padding: 30px;
    margin: 10px;
}

.container > img {
    max-width: 120px;
    margin-right: 30px;
    margin-bottom: 30px;
}

@media screen and (min-width: 600px) {
    .container {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
    }

    .app-layout-container {
        display: flex;
    }
}
