.tabla {
    width: 100%;
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    margin-top: 30px;
    background-color: rgba(88, 86, 86, 0.5);
}

@media screen and (max-width: 800px) {
    .tabla {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .app-layout-container {
        display: flex;
    }
}
