.body-layout {
    display: flex;
    /* flex: 4; */
    /* flex-direction: row; */
}

@media screen and (min-width: 600px) {
    .body-layout {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: stretch;
    }
}

@media screen and (max-width: 900px) {
    .body-layout {
        flex-wrap: wrap;
        flex-direction: initial;
        justify-content: space-evenly;
        align-items: stretch;
    }
}
